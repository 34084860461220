<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('change_user')" :is-filter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('change_user')" :is-filter="false">
                </HeaderMobile>
            </template>
            <div class="row data-form">
                <div class="col-12">
                    <ValidationObserver ref="form">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <ValidationProvider name="report" rules="required|email" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('email')">
                                        <b-input v-model="email"></b-input>
                                    </b-form-group>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 mt-3">
                                <processing-button @click="loginAs" label="change_user"
                                                   :processing="processing"></processing-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ProcessingButton from "../../../components/elements/ProcessingButton";
import UserService from "../../../services/UserService";
import Base from '@/plugins/Base'

export default {
    components: {
        ProcessingButton,
        AppLayout,
        ValidationProvider,
        ValidationObserver,
        Header,
        HeaderMobile
    },
    metaInfo() {
        return {
            title: this.$t('change_user')
        }
    },
    data() {
        return {
            email:null,
            processing: false
        }
    },
    methods: {
        async loginAs() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                this.processing = true;
                UserService.loginAs({email: this.email}).then(response=>{
                    this.login(response.data.data);
                    UserService.menu()
                        .then(responseUser => {
                            let data = responseUser.data.data;
                            this.$store.commit('auth/setMenus', data);
                            this.$router.push('/dashboard');
                        })
                }).catch(e=>{
                    this._showErrors(e);
                }).finally(()=>{
                    this.processing = false;
                });
            }
        },
        login(data) {
            if (data.access_token) {
                data.access_token = data.access_token.replace(/^"+/, '').replace(/"+$/, '');
                Base.LocalStorage.set('access_token', 'Bearer ' + data.access_token);
                this.$store.commit('auth/setToken', 'Bearer ' + data.access_token);
            }
            if (data.expires_in) {
                let expireIn = parseInt(data.expires_in) * 60000;
                Base.LocalStorage.set('access_token_expires_in', new Date().getTime() + expireIn);
                this.$store.dispatch('auth/setTimeoutTimer', expireIn)
            }

            this.$store.dispatch('auth/getUser');
        },
    }
};
</script>

